import React from "react"
import { Layout } from "@/layout"
import {
  Box,
  SEO,
  Width,
  Link,
  Text,
  SidebarBanner,
  Instagram,
  Facebook,
} from "@/components/index"

export default function Mentorship() {
  return (
    <Layout headerType="default" headerTheme="light" footerTheme="light">
      <SEO
        title="Meet Sonora’s Mentors: Mimi Fox"
        description="Sonora pairs world-class mentors with intermediate guitar students in an intensive 3-month program to help them find their breakthrough on guitar."
      />
      <Width pt="12.9rem" pb="7.2rem">
        <Box
          width={["100%", null, 10 / 12, null]}
          mx="auto"
          display="flex"
          flexDirection={["column", null, "row", null]}
        >
          <Box flex="2">
            <Text as="h1" fontWeight="bold" mb="1.2rem" mt={["2rem", null, "0rem", null]}>
              Mimi Fox
            </Text>
            <Text level="1" mb="1.2rem">
              Guitar virtuosa and composer
            </Text>
            <Box>
              <Link to="https://www.instagram.com/mimifoxjazz" as="a">
                <Instagram mr="1.2rem" />
              </Link>
              <Link to="https://www.facebook.com/mimifoxjazz/" as="a">
                <Facebook />
              </Link>
            </Box>
            <Text level="2" mt="3.6rem">
              Internationally-renowned guitar virtuosa, composer, and recording
              artist Mimi Fox is a six-time DownBeat magazine International
              Critics Poll winner and has been recognized by writers and
              colleagues alike as one of the most eloquent guitarists on today's
              scene. In one of many feature stories, Guitar Player magazine
              hailed Mimi as “a prodigious talent who has not only mastered the
              traditional forms but has managed to reinvigorate them.”
            </Text>
            <Text level="2">
              She has performed and recorded with a who's who of great artists,
              including{" "}
              <strong>
                Abbey Lincoln, Joey DeFrancesco, Charlie Byrd, Branford
                Marsalis, Diana Krall,
              </strong>{" "}
              and <strong>Stevie Wonder</strong>, among countless others. She
              performs at prestigious concert halls worldwide; recent sold-out
              shows include those at the{" "}
              <strong>Kennedy Center, Montreal Jazz Festival, </strong>
              and <strong>Newport Folk Festival</strong>.
            </Text>
            <Text level="2">
              With her 11th album as a leader or co-leader, This Bird Still
              Flies, Fox delivers a seamless but far-ranging album that’s
              breathtaking it its beauty, emotional immediacy and stylistic
              reach. The all acoustic recording captures a commanding artist in
              full - transforming folk tunes, jazz standards, original
              compositions and popular songs into exquisitely crafted
              improvisational vehicles. The new reviews rolling in could not be
              more glowing:
            </Text>
            <Text level="2">
              “Utter mastery, four stars!“ - Downbeat Magazine
            </Text>
            <Text level="2">
              "A Tour de Force from one of the preeminent practitioners of our
              time" - Midwest Record
            </Text>
            <Text level="2">
              "A dazzler from start to finish...remarkable." -Jazz Times
            </Text>
            <Text level="2">
              "Wonderful...glows like a candle." - Jazz Weekly
            </Text>
            <Text level="2">
              As a composer, Mimi has written and performed original scores for
              orchestras, documentary films, and dance projects and received
              grants from prestigious funders, such as Meet the Composer and the
              California Arts Council. Mimi has repeatedly received grants from
              the William James Association and California Arts Council to give
              concerts and workshops in California prisons as part of the highly
              successful California Arts in Corrections program. A devoted
              educator and clinician, Mimi has taught master classes worldwide
              while serving as associate professor at the{" "}
              <strong>California Jazz Conservatory</strong> in Berkeley and
              adjunct professor at <strong>New York University</strong>.
            </Text>
            <Text level="2">
              Whether delivering jaw-dropping solo sets, performing dazzling
              duets with sympathetic partners, or leading a hard-charging combo,
              Mimi is a consummate improviser who seizes the imagination of her
              listeners with her passion, soul, and unerring ear for beauty.
            </Text>
          </Box>
          <Box
            flex="1"
            mt={["6rem", null, 0, null]}
            ml={[0, null, "6rem", null]}
          >
            <SidebarBanner />
          </Box>
        </Box>
      </Width>
    </Layout>
  )
}
